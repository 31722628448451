import React from "react";

import Progress from "./Progress";

import "./Landing.css";

const Landing = ({ history, isSignedOut }) => {
  return (
    <div className="landing noselect">
      <div className="top">
        <div className="caret select">∨</div>
        <div className="top-info">
          <div className="playing-from">PLAYING FROM ALBUM</div>
          <div><b>Death of a Bachelor</b></div>
        </div>
        <div className="vertical-ellipsis select">⋮</div>
      </div>

      <div className="middle"></div>

      <div className="bottom">
        <div className="row1">
          <div>
            <div className="title"><b>Death of a Bachelor</b></div>
            <div className="artist">Panic! At The Disco</div>
          </div>
          <div className="heart select">💚</div>
        </div>

        <div className="row2">
          <Progress length={203} />
        </div>

        <div className="row3">
          <div className="select">🔀</div>
          <div className="select">⏮</div>
          <div className="play select" onClick={() => history.push(isSignedOut ? "/signIn" : "/home")}>▶</div>
          <div className="select">⏭</div>
          <div className="select">🔁</div>
        </div>

        <div className="row4">
          <div className="select">🖥</div>
          <div className="select">📃</div>
        </div>
      </div>
    </div>
  )
};

export default Landing;
