import React, { useState, useEffect } from "react";
import "./SignIn.css";

const SignIn = ({ awsClient, history, onSignIn, isSignedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [signInHandled, setSignInHandled] = useState(false);

  useEffect(() => {
    if (isSignedIn && !signInHandled) {
      history.replace("/home");
    }
  }, [history, isSignedIn, signInHandled]);

  const onSubmit = (event) => {
    event.preventDefault();

    if (!username || !password) return;

    awsClient.signIn(username, password)
    .then(() => {
      onSignIn();
      history.push("/home");
      setError("");
      setSignInHandled(true);
    })
    .catch(() => {
      setError("Incorrect credentials");
    });
  }

  return (
    <div id="sign-in">
      <p style={{ fontSize: "xx-large", margin: "0", marginBottom: "1rem" }}><b>Sign in</b></p>
      <form id="sign-in-form" onSubmit={onSubmit}>
        <div>
          <label htmlFor="username">Username:</label>
          <input type="text" id="username" value={username} onChange={(event) => setUsername(event.target.value)} />
        </div>

        <div>
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" value={password} onChange={(event) => setPassword(event.target.value)} />
        </div>

        {error && <p style={{ color: "red" }}>{error}</p>}

        <input type="submit" value="Submit" />
      </form>
    </div>
  )
};

export default SignIn;
