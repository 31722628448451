import React from "react";

import "./ProgressBar.css";

const ProgressBar = ({ startText, endText, percentage }) => {
  return (
    <div className="progress-bar">
      <div>{startText}</div>
      <input type="range" min={0} max={1} step={0.01} readOnly value={percentage} />
      <div>{endText}</div>
    </div>
  );
};

export default ProgressBar;
