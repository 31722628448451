import React, { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import Landing from "../Landing"
import Home from "../Home";
import RecordingBooth from "../RecordingBooth";

import './App.css';
import SignIn from '../SignIn/SignIn';
import Loading from './Loading';

const AuthState = Object.freeze({
  signedOut: 0,
  pending: 1,
  signedIn: 2
});

const App = ({ awsClient }) => {
  const [authState, setAuthState] = useState(AuthState.pending);
  const [videoData, setVideoData] = useState();

  useEffect(() => {
    const updateAuthState = async () => {
      const isSignedIn = await awsClient.isSignedIn();
      setAuthState(isSignedIn ? AuthState.signedIn : AuthState.signedOut);
    }
    updateAuthState();
  }, [awsClient]);

  const onFullVideoData = useCallback((videoData) => setVideoData(videoData), []);

  const contentRoutes = () => {
    switch (authState) {
      case AuthState.signedOut:
      case AuthState.pending:
        return <Route render={({ history }) => <Loading history={history} isSignedOut={authState === AuthState.signedOut} />} />;
      case AuthState.signedIn:
        return (
          <>
            <Route path="/home" render={({ history }) => (
              <Home
                history={history}
                awsClient={awsClient}
                onFullVideoData={onFullVideoData}
                videoData={videoData}
              />
            )} />
            <Route path="/video/:id" render={({ match, history }) => (
              <RecordingBooth match={match} awsClient={awsClient} onFullVideoData={onFullVideoData} videoData={videoData} overwrite={history.location.state.overwrite} />
            )} />
          </>
        );
      default:
        return <Route render={() => <p>You shouldn't see this! Please contact Daniel.</p>} />;
    }
  };

  // TODO: don't conditionally render routes
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact render={({ history }) => <Landing history={history} isSignedOut={authState === AuthState.signedOut} />} />
          <Route path="/signIn" render={({ history }) => (
              <SignIn
                history={history}
                isSignedIn={authState === AuthState.signedIn}
                awsClient={awsClient}
                onSignIn={() => setAuthState(AuthState.signedIn)}
              />
            )}
          />
          {contentRoutes()}
        </Switch>
      </div>
    </Router>
  )
}

export default App;
