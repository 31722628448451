import React, { useState, useEffect, useRef } from 'react';

import "./Home.css";
import MediaManager from '../managers/mediaManager';
import { VIDEO_COUNT } from "../contants";

// TODO: make user interact with page before playing videos (maybe always start at landing?)
const Home = ({ history, awsClient, onFullVideoData, videoData }) => {
  const [videosReady, setVideosReady] = useState(false);
  const [videoManager] = useState(() => new MediaManager(awsClient, "video"));

  const mediaFetchedRef = useRef(!Boolean(videoData));

  useEffect(() => {
    if (!videosReady) {
      if (videoData) {
        videoManager.setTracks(videoData, mediaFetchedRef.current);
        videoManager.startSynced(() => {
          setVideosReady(true);
        });
      } else {
        videoManager.fetchMedia(
          [...Array(VIDEO_COUNT).keys()],
          onFullVideoData
        );
      }
    }
  }, [videoData, videoManager, videosReady, onFullVideoData]);

  const getVideoElements = () => {
    const elements = new Array(VIDEO_COUNT);
    videoManager.tracks.forEach((video) => {
      elements[video.index] = (
        <div
          className="video"
          key={video.index}
          // TODO: instead allow user to either edit current video's metadata or record new video
          onClick={() => history.push(`/video/${video.index}`, { overwrite: true })}
          ref={(instance) => { if (instance) instance.appendChild(video.media) }} // call `play` on video
        />
      );
    });
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i]) {
        elements[i] = (
          <div
            className="video-placeholder noselect"
            key={i}
            onClick={() => history.push(`/video/${i}`, { overwrite: false })}
          >
            <p>{i}</p>
          </div>
        );
      }
    }
    return elements;
  }

  return (
    <div className="Home">
      {videosReady && getVideoElements()}
    </div>
  );
}

export default Home;