import React, { useState } from "react";

import { getTimeString } from "../util";

import "./Progress.css";

const Progress = ({ length }) => {
  const [progress, setProgress] = useState(0);

  return (
    <div>
      <input
        id="progress"
        type="range"
        min="0"
        max={length}
        value={progress}
        onChange={(e) => setProgress(Number(e.target.value))} />
      <div id="times">
        <div>{getTimeString(progress)}</div>
        <div>{getTimeString(length)}</div>
      </div>
    </div>
  )
};

export default Progress;
