import React, { useState, useCallback, useRef, useEffect } from "react";

import "./Syncer.css";

const Syncer = ({ blob, audioManager, defaultOffset, onSubmit, onReset }) => {
  const [offset, setOffset] = useState(defaultOffset);
  const [intermediateOffset, setIntermediateOffset] = useState(defaultOffset);
  const [gain, setGain] = useState(1);
  const [canPlay, setCanPlay] = useState(false);
  const videoRef = useRef();
  const gainNode = useRef();

  useEffect(() => {
    if(canPlay) {
      videoRef.current.currentTime = offset;
      videoRef.current.play().then(() => audioManager.play());
    }

    return () => audioManager.stop();
  }, [canPlay, offset, audioManager]);

  useEffect(() => {
    if(videoRef.current && gainNode.current) {
      gainNode.current.gain.value = gain;
    }
  }, [gain]);

  const setupGain = () => {
    const context = new AudioContext();
    const audioSource = context.createMediaElementSource(videoRef.current);
    gainNode.current = context.createGain();
    audioSource.connect(gainNode.current);
    gainNode.current.connect(context.destination);
  };

  const videoCallback = useCallback((node) => {
    if(node !== null) {
      videoRef.current = node;

      const videoURL = URL.createObjectURL(blob);
      node.src = videoURL;

      const canPlayThroughHandler = () => {
        setCanPlay(true);
        node.removeEventListener("canplaythrough", canPlayThroughHandler);
      };
      node.addEventListener("canplaythrough", canPlayThroughHandler);

      setupGain();
    }
  }, [blob]);

  return (
    <div className="syncer">
      <video ref={videoCallback} />

      <div className="syncer-setting">
        <h2>Start time</h2>
        <p className="tooltip">Line your audio up to be in sync with everyone else's. Drag the slider towards the left to start your audio earlier. Drag it towards the right to start your audio later.</p>
        <input
          type="range"
          min={0}
          max={defaultOffset * 2}
          step={0.1}
          value={intermediateOffset}
          onChange={(event) => setIntermediateOffset(event.target.value)}
          onMouseUp={(event) => setOffset(event.target.value)}
          onTouchEnd={(event) => setOffset(event.target.value)} />
      </div>

      <div className="syncer-setting">
        <h2>Volume</h2>
        <p className="tooltip">If your audio sounds too loud or too quiet, you can adjust it here. The middle of the slider is normal volume.</p>
        <input
          type="range"
          min={0}
          max={2}
          step={0.1}
          value={gain}
          onChange={(event) => setGain(event.target.value)} />
      </div>

      <div>
        <p className="tooltip">Once you're happy with the above settings, click "Submit" to add/update your video!</p>
        <button className="submit" onClick={() => onSubmit(blob, Number(offset), Number(gain), videoRef.current)}>Submit</button>
      </div>

      <div>
        <p className="tooltip">Or, if you want to re-record your video, click "Reset"</p>
        <button className="reset" onClick={onReset}>Reset</button>
      </div>
    </div>
  );
};

export default Syncer;
