import Amplify, { Auth, Storage, API } from 'aws-amplify';
import { restApiEndpoint, stagingBucket, videoBucket } from './constants';

const apiName = "restApi";

export default class AwsClient {
  constructor() {
    Amplify.configure({
      Auth: {
        identityPoolId: "us-west-2:d711ac1f-4469-4fa1-b2ca-ee643099c77b",
        region: "us-west-2",
        userPoolId: "us-west-2_SdSKvuugr",
        userPoolWebClientId: "2n3dvsarqhp87ig15n06ur59iq",
        mandatorySignIn: true
      },
      Storage: {
        AWSS3: {
          region: "us-west-2"
        }
      },
      API: {
        endpoints: [
          {
            name: apiName,
            endpoint: restApiEndpoint,
            custom_header: async () => { 
              return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` } 
            }
          }
        ]
      }
    });
    Storage.configure({ customPrefix: { public: "" } });
  }

  async isSignedIn() {
    try {
      await Auth.currentSession();
      return true;
    } catch (error) {
      return false;
    }
  }

  async signIn(username, password) {
    await Auth.signIn(username, password);
  }

  async getVideoUrl(id) {
    try {
      return await Storage.get(`video${id}.mp4`, {
        bucket: videoBucket
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async stageVideo(id, blob) {
    console.log(`Staging video with ID ${id}, size: ${blob.size}`);
    await Storage.put(`video${id}.mp4`, blob, {
      bucket: stagingBucket
    });
  }

  async createVideo(id) {
    await API.post(apiName, `/video/${id}`, {
      body: {
        stagingKey: `video${id}.mp4`
      }
    });
  }

  async updateVideo(id) {
    await API.put(apiName, `/video/${id}`, {
      body: {
        stagingKey: `video${id}.mp4`
      }
    });
  }

  // TODO: add batch get
  async getMetadata(id) {
    try {
      const metadata = await API.get(apiName, `/video/${id}/metadata`, {
        "responseType": "json"
      });
      return metadata;
    } catch (error) {
      console.error(error);
      return { gain: 1, offset: 0 };
    }
  }

  async addMetadata(id, metadata) {
    if (!("offset" in metadata) || !("gain" in metadata)) {
      throw new Error("metadata must have both gain and offset properties");
    }

    await API.post(apiName, `/video/${id}/metadata`, {
      body: {
        offset: metadata.offset,
        gain: metadata.gain
      }
    });
  }

  async updateMetadata(id, metadata) {
    await API.put(apiName, `/video/${id}/metadata`, {
      body: {
        offset: metadata.offset,
        gain: metadata.gain
      }
    });
  }
}
