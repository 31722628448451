import TrackMap from "./trackMap";

class MediaManager {
  constructor(awsClient, mediaType) {
    this.awsClient = awsClient;
    this.mediaType = mediaType;
    this.audioContext = new AudioContext();
    this.tracksFinished = 0;
    this.onEnded = null;
    this.trackMap = new TrackMap();
  }

  get duration() { return this.trackMap.maxDuration }

  get currentTime() { return this.trackMap.currentTime }

  get tracks() { return this.trackMap.tracks }

  addEndedListener(media) {
    media.addEventListener("ended", () => {
      if (++this.tracksFinished === this.trackMap.length) {
        if (this.onEnded) this.onEnded();
        this.tracksFinished = 0;
        this.tracks.forEach((track) => {
          track.media.currentTime = track.metadata.offset;
        });
      }
    });
  }

  setTracks(tracks, newTracks) {
    if (newTracks) {
      this.trackMap = new TrackMap();
      tracks.forEach((track) => {
        this.setTrack(track.index, track.media, track.metadata);
      });
    } else {
      this.trackMap = TrackMap.fromArray(tracks);

      tracks.forEach((track) => {
        track.media.pause();
        track.media.currentTime = track.metadata.offset;
        this.addEndedListener(track.media);
      });
    }
  }

  setTrack(index, media, metadata) {
    media.pause();
    media.currentTime = metadata.offset;

    const source = this.audioContext.createMediaElementSource(media);
    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = metadata.gain;
    source.connect(gainNode);
    gainNode.connect(this.audioContext.destination);
    this.trackMap.add(index, { media, metadata, index });
    this.addEndedListener(media);
  }

  setMedia(mediaUrls, metadataArray, callback) {
    let elementsProcessed = 0;
    const tracks = [];
    for (let i = 0; i < mediaUrls.length; i++) {
      const mediaUrl = mediaUrls[i];
      const metadata = metadataArray[i];

      const media = document.createElement(this.mediaType);
      media.src = mediaUrl;
      media.crossOrigin = "anonymous";
      media.currentTime = metadata.offset;

      // eslint-disable-next-line no-loop-func
      const canPlayThroughHandler = () => {
        tracks.push({ media, metadata, index: i });

        if(++elementsProcessed === mediaUrls.length) {
          callback(tracks);
        }
  
        media.removeEventListener("canplaythrough", canPlayThroughHandler);
      }
      media.addEventListener("canplaythrough", canPlayThroughHandler);

      // eslint-disable-next-line no-loop-func
      const errorHandler = () => {
        if(++elementsProcessed === mediaUrls.length) {
          callback(tracks);
        }
  
        media.removeEventListener("error", errorHandler);
      }
      media.addEventListener("error", errorHandler);
    }
  }

  /*
    Potential caching opportunities (both reliant on storing files locally, likely using IndexDB):
    1. Use the `If-Modified-Since` header when making a GET request, will return 304 if there's not a new file
    2. Get the HEAD of an object and compare the last modified date to the cached file's
  */
  fetchMedia(ids, callback) {
    const mediaUrlPromises = Promise.all(ids.map((id) => this.awsClient.getVideoUrl(id)));
    const metadataPromises = Promise.all(ids.map((id) => this.awsClient.getMetadata(id)));
    Promise.all([mediaUrlPromises, metadataPromises])
    .then(([mediaUrls, metadataArray]) => {
      this.setMedia(
        mediaUrls.filter((el) => Boolean(el)),
        metadataArray.filter((el) => Boolean(el)),
        callback
      )
    })
    .catch((error) => console.error(error));
  }

  play(position) {
    let alterTime = true;
    if(position === null || position === undefined) {
      if(this.tracksFinished === this.trackMap.length) {
        position = 0;
      } else {
        alterTime = false;
      }
    }

    this.tracks.forEach((track) => {
      if(alterTime) track.media.currentTime = track.metadata.offset + position;
      track.media.play();
    });
  }

  pause() {
    this.tracks.forEach((track) => track.media.pause());
  }

  stop() {
    this.tracks.forEach((track) => {
      track.media.pause();
      track.media.currentTime = track.metadata.offset;
    });
    this.tracksFinished = 0;
  }

  // See: https://github.com/danielisgr8/doab-website/issues/1
  startSynced(callback) {
    const playPromises = [];
    this.tracks.forEach((track) => {
      track.media.volume = 0;
      playPromises.push(track.media.play());
    });
    Promise.all(playPromises)
    .then(() => {
      let timeUpdates = 0;
      this.tracks.forEach((track) => {
        const timeUpdateHandler = () => {
          if (++timeUpdates === this.tracks.length) {
            this.tracks.forEach((track) => {
              track.media.volume = 1;
              track.media.currentTime = track.metadata.offset;
            });
            callback();
          }
          track.media.removeEventListener("timeupdate", timeUpdateHandler);
        };
        track.media.addEventListener("timeupdate", timeUpdateHandler);
      });
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export default MediaManager;
