import React, { useEffect } from "react";

const Loading = ({ history, isSignedOut }) => {
  useEffect(() => {
    if (isSignedOut) {
      history.push("/signIn");
    }
  }, [history, isSignedOut]);

  return <p>Loading...</p>;
};

export default Loading;
