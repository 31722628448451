export default class TrackMap {
  constructor() {
    this.map = {};
    this.length = 0;
    this.maxDuration = 0;
    this.longestTrack = null;
  }

  static fromArray(array) {
    const trackMap = new TrackMap();
    array.forEach((track) => {
      trackMap.add(track.index, track);
    });
    return trackMap;
  }

  get tracks() {
    return Object.values(this.map);
  }

  get orderedTracks() {
    return Object.keys(this.map).sort().map((index) => this.map[index]);
  }

  get currentTime() {
    return this.longestTrack.media.currentTime - this.longestTrack.metadata.offset;
  }

  add(index, track) {
    if (this.map[index] === undefined) {
      this.length++;
    }
    this.map[index] = track;

    const duration = track.media.duration - track.metadata.offset;
    if (duration > this.maxDuration) {
      this.maxDuration = duration;
      this.longestTrack = track;
    }
  }
}