import React, { useRef, useCallback, useEffect, useState } from "react";

import Countdown from "./Countdown";
import ProgressBar from "./ProgressBar";

import "./Recorder.css";
import { getTimeString } from "../util";

const Recorder = ({ userMediaStream, audioManager, audioReady, onReset, onRecordingEnded }) => {
  // TODO: name functions passed into useEffect calls for documentation
  const [displayProgress, setDisplayProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [mediaRecorder] = useState(() => new MediaRecorder(
    userMediaStream,
    {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000
    }
  ));

  const videoRef = useCallback((node) => {
    if(node !== null) {
      node.muted = true;
      node.srcObject = userMediaStream;
      node.addEventListener("canplay", () => node.play());
    }
  }, [userMediaStream]);

  const beatHandlerTimeoutRef = useRef(null);
  const videoBuffer = useRef([]);

  useEffect(() => {
    const dataAvailableHandler = (event) => {
      if(event.data.size > 0) {
        videoBuffer.current.push(event.data);
      }
    };
    mediaRecorder.addEventListener("dataavailable", dataAvailableHandler);

    const stopHandler = () => onRecordingEnded(new Blob(videoBuffer.current, { type: mediaRecorder.mimeType }));
    mediaRecorder.addEventListener("stop", stopHandler);

    return () => {
      mediaRecorder.removeEventListener("dataavailable", dataAvailableHandler);
      mediaRecorder.removeEventListener("stop", stopHandler);
    };
  }, [mediaRecorder, onRecordingEnded]);

  useEffect(() => {
    let interval = null;
    if(displayProgress) {
      interval = setInterval(() => {
        const newProgress = audioManager.currentTime / audioManager.duration;
        setProgress(newProgress);
        if(newProgress >= 1) clearInterval(interval);
      }, 500);
      setProgress(audioManager.currentTime / audioManager.duration);
    }

    return () => { 
      if(interval !== null) clearInterval(interval);
      if(beatHandlerTimeoutRef.current !== null) clearTimeout(beatHandlerTimeoutRef.current);
    };
  }, [displayProgress, audioManager]);

  useEffect(() => {
    if (audioReady) {
      audioManager.onEnded = () => mediaRecorder.stop();

      return () => {
        audioManager.onEnded = null;
        audioManager.stop();
      }
    }
  }, [audioManager, audioReady, mediaRecorder]);

  const beatHandlers = {
    4: () => {
      mediaRecorder.start();
    },
    1: () => {
      audioManager.play();
      beatHandlerTimeoutRef.current = setTimeout(() => setDisplayProgress(true), 2000);
    }
  };

  return audioReady
    ? (
        <div id="recorder">
          <div className="top">
            {displayProgress
              ? <ProgressBar startText="0:00" endText={getTimeString(audioManager.duration)} percentage={progress} />
              : <Countdown beatMax={4} bpm={55} beatHandlers={beatHandlers} />}
          </div>
          <video id="preview" ref={videoRef} />
          <button className="reset" onClick={onReset}>Reset</button>
        </div>
      )
    : <div>Please wait...</div>;
};

export default Recorder;
