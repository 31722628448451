import React, { useState, useEffect, useCallback, useRef } from "react";

import Guide from "./Guide";
import Recorder from "./Recorder";
import Header from "../Header";
import Syncer from "./Syncer";
import MediaManager from "../managers/mediaManager";
import { VIDEO_COUNT } from "../contants";

import "./RecordingBooth.css";

const RecordingBooth = ({ match, history, awsClient, onFullVideoData, videoData, overwrite = false }) => {
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState(null);
  const [userMediaStream, setUserMediaStream] = useState(null);
  const [audioManager] = useState(() => new MediaManager(awsClient, "video"));
  const [audioReady, setAudioReady] = useState(false);

  const mediaFetchedRef = useRef(!Boolean(videoData));

  useEffect(() => {
    if (videoData) {
      audioManager.setTracks(videoData, mediaFetchedRef.current);
      setAudioReady(true);
    } else {
      audioManager.fetchMedia(
        [...Array(VIDEO_COUNT).keys()],
        onFullVideoData
      );
    }
  }, [videoData, audioManager, onFullVideoData]);

  useEffect(() => {
    if(userMediaStream === null) {
      navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: "user" } })
      .then((stream) => {
        setUserMediaStream(stream);
      })
      .catch((err) => {
        console.error(err);
        // TODO: toast error?
      });
    }

    return () => {
      if(userMediaStream !== null) userMediaStream.getTracks().forEach((track) => track.stop());
    }
  }, [userMediaStream]);

  const submitCallback = useCallback((blob, offset, gain, videoElement) => {
    const videoId = match.params.id;
    const metadata = { offset, gain };
    Promise.all([
      new Promise(async (resolve, reject) => {
        try {
          await awsClient.stageVideo(videoId, blob);
          await (overwrite ? awsClient.updateVideo(videoId) : awsClient.createVideo(videoId));
          resolve();
        } catch (error) {
          reject(error);
        }
      }),
      overwrite ? awsClient.updateMetadata(videoId, metadata) : awsClient.addMetadata(videoId, metadata)
    ])
    .then(() => {
      history.push("/home");
    })
    .catch((error) => {
      console.error(error);
    });
  }, [awsClient, history, match.params.id, overwrite]);

  const onRecordingEnded = (blob) => {
    userMediaStream.getTracks().forEach((track) => track.stop());
    setRecordingBlob(blob);
  };

  const onReset = () => {
    setRecordingStarted(false);
    setRecordingBlob(null);
    setUserMediaStream(null);
  };

  let rendered;
  if(recordingStarted) {
    if(recordingBlob) {
      rendered = <Syncer
                  blob={recordingBlob}
                  audioManager={audioManager}
                  defaultOffset={3.4}
                  onSubmit={submitCallback}
                  onReset={onReset} />;
    } else {
      rendered = (
        <Recorder
          userMediaStream={userMediaStream}
          audioManager={audioManager}
          audioReady={audioReady}
          onReset={onReset}
          onRecordingEnded={onRecordingEnded} />
      );
    }
  } else {
    rendered = <Guide disabled={!userMediaStream} onClick={() => setRecordingStarted(true)} />;
  }

  return (
    <div id="recording-booth">
      <Header title={"Recording Booth"} />
      {rendered}
    </div>
  );
};

export default RecordingBooth;
