import React, { useEffect, useState } from "react";

const Countdown = ({ beatMax = 4, bpm = 60, beatHandlers }) => {
  const [countdownTime, setCountdownTime] = useState(-1);

  useEffect(() => {
    // TODO: make sound
    if(countdownTime >= 0 && beatHandlers && beatHandlers[countdownTime]) beatHandlers[countdownTime]();
  }, [countdownTime, beatHandlers]);

  useEffect(() => {
    const start = Date.now();
    const interval = setInterval(() => {
      const ellapsedMs = Date.now() - start;
      const beatsPerMs = bpm / 60 / 1000;
      const beatsEllapsed = Math.floor(beatsPerMs * ellapsedMs);
      const beatsRemaining = beatMax - beatsEllapsed;

      setCountdownTime(beatsRemaining);
      if(beatsRemaining <= 0) clearInterval(interval);
    }, Math.ceil(60 / bpm * 1000));

    setCountdownTime(beatMax);

    return () => clearInterval(interval);
  }, [beatMax, bpm, beatHandlers]);

  return <div style={{ fontSize: "4rem" }}>{countdownTime}</div>
}

export default Countdown;
