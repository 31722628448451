import React from "react";

import "./Guide.css";

const Guide = ({ disabled = false, onClick }) => {
  return (
    <div>
      <ul id="guide-list">
        <li>Make sure to allow video and audio recording to capture your lovely voice.</li>
        <li>After clicking "Start recording" a countdown will start.</li>
        <li>You will start singing when the countdown hits one (NOT zero).</li>
        <li>Other singers will start at the same time to guide you 😊.</li>
        <li>I recommend using headphones so the recording doesn't get mucked up 🤮.</li>
        <li>Feel free to hit "Reset" if you don't nail it the first time!</li>
      </ul>
      <button disabled={disabled} onClick={onClick}>Start recording</button>
    </div>
  );
};

export default Guide;
